import React from 'react';
import oxo5qt from './assets/oxo-5qt.jpg';
import kitchenBackground from './assets/kitchen-background.jpg';
import './App.sass'

function App() {
    return (
        <div className="App">
            <section className="section">
                <div className="container">
                    {/*<div className="card-image">*/}
                    {/*    <figure className="image is-3by1">*/}
                    {/*        <img src={kitchenBackground} className="kitchen-background-img" />*/}
                    {/*    </figure>*/}
                    {/*</div>*/}
                    <h1 className="title">
                        Kitchenware Studio
                    </h1>
                    <p className="subtitle">
                        Having fun while cooking is all that matter
                    </p>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="columns">
                        <div className="column">
                            <figure className="media-left">
                                <p className="image">
                                    <img src={oxo5qt} className="product-img"/>
                                </p>
                            </figure>
                        </div>
                        <div className="column">
                            <div className="title is-2">Oxo 5 qt steel wok</div>
                            <ul>
                                <li>Heat-Radiant aluminum core fused between two layers of stainless steel ensures
                                    even
                                    cooking from every side
                                </li>
                                <li>Unique rolled edges designed for drip free pouring of sauces, reductions and
                                    more
                                </li>
                                <li>Contoured handles designed to stay cool to the touch for a comfortable grip</li>
                                <li>Durable, scratch-resistant materials are oven safe up to 430 Degree Fahrenheit
                                    and
                                    dishwasher safe
                                </li>
                                <li>Works on all stovetops, including induction</li>
                            </ul>
                            <p className="cta-btn">
                                <button className="button is-link">Buy now from Amazon</button>
                            </p>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    );
}

export default App;
